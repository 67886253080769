// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  display: flex;
  justify-content: space-between;
  background-color: seashell;
  border-bottom: 2px solid rgb(234, 227, 227);
  padding: 4px 10px 4px 10px;
}

.Nav-title {
  font-size: 1.2rem;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

nav a {
  padding: 0.5em;
  text-decoration: none;
}

nav a.active {
  color: black;
  text-decoration: none;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/routes-nav/Nav.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,0BAA0B;EAC1B,2CAA2C;EAC3C,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,SAAS;EACT,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":["nav {\n  display: flex;\n  justify-content: space-between;\n  background-color: seashell;\n  border-bottom: 2px solid rgb(234, 227, 227);\n  padding: 4px 10px 4px 10px;\n}\n\n.Nav-title {\n  font-size: 1.2rem;\n}\n\nnav ul {\n  list-style: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 1rem;\n  padding: 0;\n  margin: 0;\n}\n\nnav a {\n  padding: 0.5em;\n  text-decoration: none;\n}\n\nnav a.active {\n  color: black;\n  text-decoration: none;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

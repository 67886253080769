// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyCard {
  max-height: 300px;
  margin-bottom: 1rem;
  padding: 5px;
  border: none;
}

.CompanyCard img {
  width: 50px;
}

.CompanyCard:hover {
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}`, "",{"version":3,"sources":["webpack://./src/companies/CompanyCard.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,wEAAwE;AAC1E","sourcesContent":[".CompanyCard {\n  max-height: 300px;\n  margin-bottom: 1rem;\n  padding: 5px;\n  border: none;\n}\n\n.CompanyCard img {\n  width: 50px;\n}\n\n.CompanyCard:hover {\n  text-decoration: none;\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

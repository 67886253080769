// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileForm {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}

.ProfileForm h3 {
  color: #ffffff;
  text-shadow: 2px 2px 5px rgb(52, 48, 47);
}`, "",{"version":3,"sources":["webpack://./src/profiles/ProfileForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,wCAAwC;AAC1C","sourcesContent":[".ProfileForm {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n  text-align: center;\n}\n\n.ProfileForm h3 {\n  color: #ffffff;\n  text-shadow: 2px 2px 5px rgb(52, 48, 47);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
